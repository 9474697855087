export type MenuBranch = {
  label: string
  children: MenuItem[]
}

export type MenuLeaf = {
  label: string
  url: string
  status?: 'highlighted' | 'incoming'
}

export type MenuItem = { target?: '_self' | '_blank' } & (MenuLeaf | MenuBranch)

export const menuStructure: MenuItem[] = [
  {
    label: 'Prodotti',
    children: [
      {
        url: '/conto-aziendale',
        label: 'Conto aziendale'
      },
      {
        url: '/carta-di-credito-aziendale',
        label: 'Carta di credito'
      },
      {
        url: '/carte-aziendali-di-debito-fisiche-e-virtuali',
        label: 'Carte di debito'
      },
      {
        url: '/amministrazione',
        label: 'Amministrazione'
      },
      {
        url: '/apertura-partita-iva',
        label: 'Apertura partita IVA'
      }
    ]
  },
  {
    label: 'Soluzioni',
    children: [
      {
        url: '/nuove-aziende',
        label: 'Nuove aziende e startup'
      },
      {
        url: '/e-commerce',
        label: 'E-commerce'
      },
      {
        url: '/logistica-trasporti',
        label: 'Logistica e trasporti'
      },
      {
        url: '/associazioni',
        label: 'Associazioni'
      }
    ]
  },
  {
    label: 'Prezzi',
    url: '/prezzi'
  },
  {
    label: 'Azienda',
    children: [
      {
        url: '/about',
        label: 'Chi siamo'
      },
      {
        url: '/lavora-con-noi',
        label: 'Lavora con noi'
      },
      {
        url: '/blog',
        label: 'Blog'
      },
      {
        url: 'https://support.tot.money/it/articles/58090-come-vi-posso-contattare',
        label: 'Contatti'
      }
    ]
  },
  {
    label: 'Supporto',
    url: 'https://support.tot.money',
    target: '_blank'
  }
]
