import Head from 'next/head'

export type TMetaFields = {
  title?: string
  description?: string
  image?: string
}

const MetaFields = ({ title, description, image }: TMetaFields) => {
  return (
    <Head>
      {title ? (
        <>
          <title>{title}</title>
          <meta property='og:title' content={title} />
          <meta name='twitter:title' content={title} />
        </>
      ) : null}

      {description ? (
        <>
          <meta property='og:description' content={description} />
          <meta name='description' content={description} />
          <meta name='twitter:description' content={description} />
        </>
      ) : null}

      {image ? (
        <>
          <meta property='og:image' content={image + '?fit=crop&w=1200&h=630'} />
          <meta property='og:image:width' content='1200' />
          <meta property='og:image:height' content='630' />
          <meta name='twitter:image' content={image} />
          <meta name='twitter:card' content='summary_large_image' />
        </>
      ) : null}

      <meta property='og:locale' content='it_IT' />
      <meta property='og:type' content='website' key='type' />
      <meta name='twitter:site' content='@wearetotmoney' />
    </Head>
  )
}

export default MetaFields
