import Image from 'next/image'
import styled from '@emotion/styled'
import { mq } from '@totmoney/grid'
import { baseColors, lightColors } from '@totmoney/colors'
import { Button } from '@totmoney/button'
import { Input } from '@totmoney/input'
import {
  faFacebookF,
  faInstagram,
  faXTwitter,
  faLinkedinIn,
  IconDefinition,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Document } from '@contentful/rich-text-types'

import Grid from 'components/Grid'
import { Paragraph, Caption, Label } from 'components/Typography'
import Wrapper from 'components/Wrapper'
import Breadcrumbs, { TCrumb } from 'components/Breadcrumbs'

import useEmailValidation from 'helpers/hooks/useEmailValidation'
import data from 'data/footer.json'
import Container from './Container'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { LinkTrackEvent } from 'helpers/events/components/LinkTrackEvent'
import isPropValid from '@emotion/is-prop-valid'


const SOCIAL_ICONS_MAPPING: Record<string, IconDefinition> = {
  linkedIn: faLinkedinIn,
  instagram: faInstagram,
  facebook: faFacebookF,
  twitter: faXTwitter,
}

const Logo = styled(LinkTrackEvent, {shouldForwardProp: isPropValid})({
  gridColumnEnd: 'span 2',
  paddingBlockEnd: '1.5rem',
  height: 'fit-content',

  [mq.xs]: {
    paddingBlockEnd: '0rem',
  },
})

const Menu = styled.nav({
  gridColumnEnd: 'span 4',
  display: 'flex',
  flexDirection: 'column',

  ['ul']: {
    padding: '0',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    rowGap: '1.5rem',
    paddingBlock: '1.5rem',
  },

  ['li']: {
    width: '100%',
    listStyle: 'none',
    cursor: 'pointer',

    [mq.xs]: {
      width: 'fit-content',
    },
  },

  [mq.xs]: {
    gridColumnEnd: 'span 2',

    ['ul']: {
      paddingBlock: '2.5rem 0',
    },
  },
})

const MenuLink = styled(LinkTrackEvent, {shouldForwardProp: isPropValid})({
  color: 'currentcolor',

  ['&:hover']: {
    color: 'currentcolor',
    opacity: '.8',
  },
})

const SectionName = styled(Paragraph)({
  textTransform: 'uppercase',
  borderBlock: '1px solid currentColor',
  paddingBlock: '1.5rem',

  [mq.xs]: {
    border: 'none',
    padding: '0',
    margin: '0',
  },
})

const FormContainer = styled.form({
  display: 'grid',
  gridTemplateColumns: '1fr',
  flexDirection: 'column',
  alignItems: 'flex-start',
  columnGap: '.5rem',
  paddingBlock: '1rem 3rem',

  [mq.xs]: {
    paddingBlock: '0',
    gridTemplateColumns: '100% auto',
  },
})

const InputField = styled.div({
  minWidth: '100%',

  [' div']: {
    marginBlockEnd: '1rem',
  },
})

const Divider = styled.hr({
  gridColumnEnd: 'span 4',
  minWidth: '100%',
  marginBlock: '3.5rem',
  height: '1px',
  border: '0',
  backgroundColor: lightColors.neutral.lowest,

  [mq.xs]: {
    gridColumnEnd: 'span 12',
    marginBlock: '4.5rem',
  },
})

const SocialList = styled.div({
  gridColumnEnd: 'span 4',
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '.5rem',

  [mq.xs]: {
    gridColumn: '10/13',
    justifyContent: 'flex-end',
  },
})

const SocialIcon = styled(LinkTrackEvent, {shouldForwardProp: isPropValid})({
  height: '3rem',
  width: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '3rem',
  backgroundColor: 'white',
  color: baseColors.darkBlue[1000],
  border: `8px solid ${baseColors.darkBlue[1000]}`,
})

const Legal = styled.div({
  ['ul']: {
    padding: '0',
    display: 'flex',
    flexDirection: 'row',

    ['li + li']: {
      marginLeft: '5px',
    },

    [mq.sm]: {
      marginBockStart: '0',
    },
  },

  ['li']: {
    listStyle: 'none',

    ['&:after']: {
      content: '" |"',
    },

    [':last-child:after']: {
      content: '""',
    },
  },

  [mq.md]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',

    ['ul']: {
      flexDirection: 'row',

      ['li']: {
        marginLeft: '5px',
      },
    },
  },
})

const LegalLink = styled(LinkTrackEvent, {shouldForwardProp: isPropValid})({
  color: 'currentcolor',

  ['&:hover']: {
    color: 'currentcolor',
    opacity: '.8',
  },
})

const FooterForm = () => {
  const { email, message, status, onChange, onSubmit } = useEmailValidation()

  return (
    <FormContainer
      css={{ gridColumnEnd: 'span 4', [mq.xs]: { gridColumnEnd: 'span 5' } }}
      onSubmit={onSubmit}
    >
      {data.inputText ? (
        <InputField>
          <Input
            name='email'
            type='email'
            required
            placeholder={data.inputText.placeholder}
            value={email}
            onChange={onChange}
            invalid={status === 'error' || status === 'warning'}
            errorMessage={message}
          />
        </InputField>
      ) : null}
      {data.cta ? (
        <Button
          css={{ marginBlockEnd: '1rem' }}
          loading={status === 'pending'}
          disabled={status === 'success' || status === 'error'}
          type='submit'
        >
          {data.cta.label}
        </Button>
      ) : null}
      <Caption css={{}}>
        {data.inputText.helpertext}
        <LegalLink type='link' contextText='footer' ctaText={data.inputText.helpertext} href={data.inputText.href}>{data.inputText.link}</LegalLink>
      </Caption>
    </FormContainer>
  )
}

const Footer = ({
  isSlim,
  disclaimer,
  crumbs,
}: {
  isSlim?: boolean
  disclaimer?: { json: Document }
  crumbs?: TCrumb[]
}) => {
  const currYear = () => {
    return new Date().getFullYear().toString()
  }

  return (
    <>
      {crumbs ? (
        <Wrapper paddingTop={[0, 0]} paddingBottom={[0, 0]} bgColor={baseColors.darkBlue[800]}>
          <Breadcrumbs crumbs={crumbs} />
        </Wrapper>
      ) : null}
      <Wrapper bgColor={baseColors.darkBlue[1000]} paddingTop={disclaimer ? [1, 2] : [2, 3]}>
        <footer css={{ color: lightColors.neutral.lowest, ul: { margin: 0 } }}>
          {disclaimer ? (
            <>
              <Container css={{ fontSize: '0.75rem' }}>
                {documentToReactComponents(disclaimer.json)}
              </Container>
              <Grid>
                <Divider css={{ marginBlock: '3rem', [mq.xs]: { marginBlock: '3rem' } }} />
              </Grid>
            </>
          ) : null}
          <Grid>
            {data.logo ? (
              <Logo type='link' contextText='' ctaText={data.cta.label} href={data.logo.url}>
                  <Image
                    src={data.logo?.path}
                    alt={data.logo?.text}
                    width={data.logo ?  Number(data.logo.width) : undefined}
                    height={data.logo ? Number(data.logo.height) : undefined}
                  />
              </Logo>
            ) : null}

            {data.social && isSlim ? (
              <SocialList>
                {data.social.icons
                  ? data.social.icons.map((icon, i) => (
                      <SocialIcon
                        type='link'
                        contextText='footer'
                        ctaText={icon.name}
                        target='_blank'
                        rel='noreferrer'
                        href={icon.href}
                        key={`${icon.href}-${i}`}
                      >
                        <FontAwesomeIcon icon={SOCIAL_ICONS_MAPPING[icon.name]} />
                      </SocialIcon>
                    ))
                  : null}
              </SocialList>
            ) : null}

            {data.links && !isSlim
              ? data.links.map((group) => (
                  <Menu key={group.title}>
                    <SectionName weight='bold'>{group.title}</SectionName>
                    <ul>
                      {group.items.map((item) => (
                        <li key={item.label}>
                          <MenuLink type='link' ctaText={item.label} contextText='footer' href={item.url}>{item.label}</MenuLink>
                        </li>
                      ))}
                    </ul>
                  </Menu>
                ))
              : null}
          </Grid>
          {!isSlim ? (
            <Grid>
              <Divider />
              {data.newsletter ? (
                <div css={{ gridColumnEnd: 'span 4', [mq.xs]: { gridColumnEnd: 'span 2' } }}>
                  <Paragraph weight='bold'>{data.newsletter.title}</Paragraph>
                  <Paragraph>{data.newsletter.subtitle}</Paragraph>
                </div>
              ) : null}
              <FooterForm/>
              {data.social ? (
                <SocialList>
                  {data.social.icons
                    ? data.social.icons.map((icon, i) => (
                        <SocialIcon
                          contextText='footer'
                          ctaText={icon.name}
                          type='link'
                          target='_blank'
                          rel='noreferrer'
                          href={icon.href}
                          key={`${icon.href}-${i}`}
                        >
                          <FontAwesomeIcon icon={SOCIAL_ICONS_MAPPING[icon.name]} />
                        </SocialIcon>
                      ))
                    : null}
                </SocialList>
              ) : null}
              <Divider css={{ marginBlockStart: '4rem', [mq.xs]: { marginBlockEnd: '1.5rem' } }} />
            </Grid>
          ) : (
            <Grid>
              <Divider
                css={{ marginBlock: '3rem 1.5rem', [mq.xs]: { marginBlock: '3rem 1.5rem' } }}
              />
            </Grid>
          )}
          <Grid>
            {data.legal ? (
              <div css={{ gridColumn: 'span 12' }}>
                <Legal>
                  <Label size='xs'>{data.legal.text.replace('#DATE#', currYear)}</Label>
                  <ul css={{ '&::before': { [mq.md]: { content: "'\\a0—'" } } }}>
                    {data.legal.links &&
                      data.legal.links.map((link) => (
                        <Label as='li' size='xs' key={link.label}>
                          <LegalLink type='link' contextText='footer' ctaText={link.label} href={link.url}>{link.label}</LegalLink>
                        </Label>
                      ))}
                  </ul>
                </Legal>
                <Label size='xs'>{data.legal.companyInfo}</Label>
              </div>
            ) : null}
          </Grid>
        </footer>
      </Wrapper>
    </>
  )
}

export default Footer
