import {
  useValidationMessages,
  ValidationMessages,
} from 'components/context/ValidationMessagesContext'
import { ChangeEvent, FormEvent, useEffect, useReducer, useRef } from 'react'

const ZBAPI = process.env.NEXT_PUBLIC_ZBAPI

async function fetchWithTimeout(
  resource: string,
  options: RequestInit & { timeout: number } = { timeout: 8000 }
) {
  const { timeout } = options

  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  })
  clearTimeout(id)
  return response
}

type ZBResponse = {
  status: string
  response: 'pass' | 'hard_fail' | 'soft_fail'
  address: string
  did_you_mean: string | null
}

const validateEmail = async (
  email: string,
  validationMessages: ValidationMessages
): Promise<ZBResponse & { message: string }> => {
  try {
    const res = (await fetchWithTimeout(ZBAPI!, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      timeout: 4000,
      body: JSON.stringify({
        email,
        ip: '',
      }),
    }).then((res) => res.json())) as ZBResponse

    return {
      ...res,
      message: validationMessages[res.status],
    }
  } catch (e) {
    // In case of timeout/error allow the user in anyway
    // TODO: Notify Sentry?
    return {
      status: 'backend_timeout_or_error',
      response: 'pass',
      address: email,
      did_you_mean: null,
      message: validationMessages['valid'],
    }
  }
}

type State = {
  email: string
  status: 'none' | 'pending' | 'success' | 'error' | 'warning'
  message: string | null
}

type Action =
  | { type: 'set-email'; payload: string }
  | {
      type: 'set-status'
      payload: {
        message: string | null
        status: 'none' | 'pending' | 'success' | 'error' | 'warning'
      }
    }
  | {
      type: 'reset'
    }

const initialState: State = {
  email: '',
  status: 'none',
  message: '',
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'set-email':
      return { ...state, email: action.payload }
    case 'set-status':
      const { message, status } = action.payload
      return { ...state, message, status }
    case 'reset':
      return initialState
    default:
      throw new Error()
  }
}

const useEmailValidation = () => {
  const [{ status, email, message }, dispatch] = useReducer(reducer, initialState)
  const validationMessages = useValidationMessages()
  const timeout = useRef<null | ReturnType<typeof setTimeout>>(null)

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
    }
  })

  // TODO: rivedere la funzione di subscribe
  const subscribe = async () => {
    const endpoint = process.env.NEXT_PUBLIC_FORM_NEWSLETTER_EP!
    const formData = new FormData()
    formData.append('email', email)
    formData.append('environment', process.env.NEXT_PUBLIC_VERCEL_ENV!)

    fetch(endpoint, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        const { url } = response
        window.location.assign(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (status === 'pending') return

    if (status !== 'none') {
      dispatch({ type: 'set-status', payload: { message: null, status: 'none' } })
    }

    dispatch({ type: 'set-email', payload: e.target.value })
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    dispatch({ type: 'set-status', payload: { status: 'pending', message: null } })

    // The user keeps submitting:
    // - without changing an invalid value
    // - while there is a pending request
    // - an already submitted email
    // ignore the submit
    if (status === 'error' || status === 'pending' || status === 'success') return

    // Even though he received a warning, the user is confirming the submit
    // subscribe him anyway
    if (status === 'warning') {
      await subscribe()
      dispatch({ type: 'set-status', payload: { status: 'success', message: 'Tutto ok' } })
      timeout.current = setTimeout(() => dispatch({ type: 'reset' }), 5000)
      return
    }

    const validity = await validateEmail(email, validationMessages)

    switch (validity.response) {
      case 'pass': {
        // TODO: Handle errors while submitting the email
        await subscribe()
        // Show some confirmation and reset the from after a while
        dispatch({ type: 'set-status', payload: { status: 'success', message: 'Tutto ok' } })
        timeout.current = setTimeout(() => dispatch({ type: 'reset' }), 5000)
        break
      }
      case 'soft_fail': {
        dispatch({ type: 'set-status', payload: { status: 'warning', message: validity.message } })
        break
      }
      case 'hard_fail': {
        dispatch({
          type: 'set-status',
          payload: {
            status: 'error',
            message: validity.did_you_mean
              ? `${validity.message} ${validity.did_you_mean}`
              : validity.message,
          },
        })
        break
      }
    }
  }

  return { email, message, status, onChange, onSubmit }
}

export default useEmailValidation
