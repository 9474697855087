import styled from '@emotion/styled'
import { mq } from '@totmoney/grid'
import { baseColors, lightColors } from '@totmoney/colors'
import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import Grid from 'components/Grid'
import LogoTot from 'components/logos/LogoTot'
import { Badge } from '@totmoney/badge'
import { MenuBranch, MenuItem, menuStructure } from 'data/menu'
import { useRouter } from 'next/router'
import { themes } from './Wrapper'
import { useStrings } from './context/StringsContext'
import { LinkTrackEvent, LinkTrackEventProps } from 'helpers/events/components/LinkTrackEvent'
import { NavLink, NavLinkStyle } from './NavLink'
import isPropValid from '@emotion/is-prop-valid'
import { css } from '@emotion/react'

const MENU_HEIGHT = '5rem'

const StickyWrapper = styled.div({
  height: MENU_HEIGHT,
})

const StickyHeader = styled.header({
  position: 'fixed',
  zIndex: 100,
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: 'var(--header-bg-color)',
  borderBottom: '1px solid var(--header-bg-color)',
  transition: 'border-color 0.25s ease',

  '&[data-scrolled="true"]': {
    borderColor: baseColors.neutral[300],
  },
})

const HeaderWrapper = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  overflow: 'visible',
  height: MENU_HEIGHT,
})

const MobileMenuWrapper = styled.div({
  display: 'flex',
  position: 'absolute',
  zIndex: 2,
  top: 0,
  left: 0,
  width: '100%',
  height: '100vh',
  '@supports (height: 100dvh)': {
    height: '100dvh',
  },
  backgroundColor: 'white',
  transition: 'transform 0.25s ease',
  flexDirection: 'column',
  transform: 'translateX(100%)',

  '[data-mobile-menu-open="true"] &': {
    transform: 'translateX(0)',
    touchAction: 'none',
  },

  [mq.lg]: {
    display: 'flex',
    padding: 0,
    position: 'initial',
    height: 'auto',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    marginLeft: '5rem',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: '1',
    transform: 'translateX(0)',
    backgroundColor: 'transparent',
  },
})

const Nav = styled.nav({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '1.5rem',

  [mq.lg]: {
    flexDirection: 'row',
    gap: '2rem',
    alignItems: 'center',
    marginRight: '2rem',
    padding: 0,
  },

  [mq.xl]: {
    gap: '5rem',
    marginRight: '5rem',
    justifyContent: 'center',
  },
})

const ButtonWrapper = styled.div({
  display: 'flex',
  gap: '1rem',
  flexDirection: 'column',
  padding: '1rem',

  [mq.lg]: {
    padding: 0,
    flexDirection: 'row',
  },
})

const MenuOpen = styled.button({
  padding: 0,
  margin: 0,
  backgroundColor: 'transparent',
  border: 'none',
  height: '1.5rem',
  width: '1.5rem',
  position: 'relative',

  '&:before': {
    content: '""',
    position: 'absolute',
    height: '2px',
    width: '1.125rem',
    backgroundColor: 'black',
    borderRadius: '2px',
    top: '0.5rem',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    height: '2px',
    width: '1.125rem',
    backgroundColor: 'black',
    borderRadius: '2px',
    top: '1rem',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  ['[data-theme="dark"] &']: {
    '&:before, &:after': {
      backgroundColor: 'white',
    },
  },

  [mq.lg]: {
    display: 'none',
  },
})

const MenuClose = styled.button({
  padding: 0,
  margin: 0,
  backgroundColor: 'transparent',
  border: 'none',
  height: '1.5rem',
  width: '1.5rem',
  position: 'relative',

  '&:before': {
    content: '""',
    position: 'absolute',
    height: '2px',
    backgroundColor: 'black',
    borderRadius: '2px',
    width: '1.0625rem',
    top: '0.375rem',
    left: '0.375rem',
    transformOrigin: 'left',
    transform: 'translateY(-50%) rotate(45deg)',
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    height: '2px',
    width: '1.125rem',
    backgroundColor: 'black',
    borderRadius: '2px',
    top: '1rem',
    left: '0.375rem',
    bottom: '0.375rem',
    transformOrigin: 'left',
    transform: 'translateY(50%) rotate(-45deg)',
  },

  [mq.lg]: {
    display: 'none',
  },
})

const NavLinkWrapper = styled.div({
  position: 'relative',
  borderBottom: `1px solid ${baseColors.neutral[200]}`,

  [mq.lg]: {
    border: 'none',
  },
})

const DropdownWrapper = styled.div<{ active: boolean }>(({ active }) => ({
  position: 'relative',
  paddingInlineStart: '1.25rem',
  display: active ? 'block' : 'none',

  [mq.lg]: {
    display: 'block',
    position: 'absolute',
    top: '100%',
    left: '50%',
    opacity: active ? 1 : 0,
    transition: 'all 0.35s ease',
    minWidth: '190px',
    transform: active ? 'translate(-50%, 0)' : 'translate(-50%, 30px)',
    pointerEvents: active ? 'auto' : 'none',
  },
}))

const Dropdown = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  backgroundColor: 'white',
  padding: 0,

  [mq.lg]: {
    boxShadow: '0px 4px 16px rgba(29, 29, 27, 0.08)',
    padding: '2rem 3rem',
    gap: '1.5rem',
    marginTop: '1.5rem',
    borderRadius: '8px',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '50%',
      width: '1.5rem',
      height: '1.5rem',
      backgroundColor: 'white',
      transform: 'translate(-50%, -50%) rotate(45deg)',
      borderTopLeftRadius: '8px',
    },
  },
})

const MobileMenuHeader = styled(HeaderWrapper)({
  margin: 0,
  width: '100%',
  maxWidth: 'none',
  padding: '0 1.5rem',
  borderBottom: `1px solid ${baseColors.neutral[300]}`,
  flex: `0 0 ${MENU_HEIGHT}`,

  [mq.lg]: {
    display: 'none',
  },
})

const IncomingBadge = styled(Badge)({
  backgroundColor: baseColors.neutral[200],
  color: baseColors.neutral[700],
  position: 'relative',

  '&::after': {
    content: '""',
    position: 'absolute',
    top: '0',
    right: '0',
    transform: 'translate(50%, -50%)',
    width: '18px',
    height: '17px',
    backgroundImage: `url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 17' width='18' height='17'%3E%3Cpath d='m10.774 3.425 1.978.825.824 1.979a.266.266 0 0 0 .476 0l.825-1.979 1.978-.825a.266.266 0 0 0 0-.475l-1.978-.825-.825-1.978a.265.265 0 0 0-.476 0l-.824 1.978-1.979.825a.265.265 0 0 0 0 .475Zm6.081 10.15-1.978-.825-.825-1.978a.265.265 0 0 0-.476 0l-.824 1.978-1.979.825a.266.266 0 0 0 0 .476l1.979.824.824 1.979a.266.266 0 0 0 .476 0l.825-1.979 1.978-.824a.265.265 0 0 0 0-.476Zm-4.103-5.087a.533.533 0 0 0-.294-.475L8.721 6.14 6.85 2.396c-.18-.36-.77-.36-.95 0L4.034 6.14.295 8.013a.531.531 0 0 0 0 .952l3.738 1.872 1.869 3.744a.532.532 0 0 0 .95 0l1.869-3.744 3.737-1.872a.534.534 0 0 0 .294-.477Z' fill='%23191919'/%3E%3C/svg%3E")`,
  },
})

const hasLeafs = (item: MenuItem): item is MenuBranch => (item as MenuBranch).children !== undefined
const mqList = typeof window !== 'undefined' ? window.matchMedia('(min-width: 64em)') : null

const StyledLinkTrackEvent = styled(LinkTrackEvent)({ margin: 0})


const StyledLinkLogo = styled(LinkTrackEvent)`
  position: relative;
  height: 1.5rem;
  min-width: 3.625rem;
  `


type MenuRootExtraProps = {
  isScrolled: boolean
  isMobile: boolean
  contentfulTheme?: string
  hasLeafs: boolean
}


const MenuLeafStyled = styled.div<MenuRootExtraProps>`
  ${NavLinkStyle};
  display: 'flex';
  color: ${(props) => props.contentfulTheme === 'dark' && !props.isScrolled && !props.isMobile ? 'white' : 'inherit'};
  justify-content: ${(props) => props.hasLeafs ? 'space-between' : undefined};
  align-items: ${(props) =>  props.hasLeafs ? 'center' : undefined };
`

const MenuRootStyled = styled(LinkTrackEvent, { shouldForwardProp: isPropValid })<MenuRootExtraProps>(({isMobile, isScrolled, contentfulTheme}) => ({
  color: contentfulTheme === 'dark' && !isScrolled && !isMobile ? 'white' : 'inherit'
}))

type MenuRootProps = LinkTrackEventProps & MenuRootExtraProps
const MenuRoot: React.FC<MenuRootProps> = ({ contentfulTheme, isScrolled, isMobile, hasLeafs, ...props }) => {
  if (hasLeafs) {
    return <MenuLeafStyled contentfulTheme={contentfulTheme} isScrolled={isScrolled} isMobile={isMobile} hasLeafs={hasLeafs}>{props.children}</MenuLeafStyled>
  } else {
    return <MenuRootStyled {...props} hasLeafs={hasLeafs} contentfulTheme={contentfulTheme} isScrolled={isScrolled} isMobile={isMobile} />
  }
}

type Header = {
  theme?: string
}
const Header: React.FC<Header> = ({ theme }) => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [activeLink, setActiveLink] = useState<undefined | number>()
  const [isOpen, setIsOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const stickyHeaderRef = useRef<HTMLDivElement | null>(null)
  const { events } = useRouter()
  const strings = useStrings()

  const StyledMenuItem = styled(LinkTrackEvent, { shouldForwardProp: isPropValid })<{ status?: 'highlighted' | 'incoming' }>`
    color: ${({ status }) =>
      status === 'incoming' ? baseColors.neutral[400] : status === 'highlighted' ? baseColors.blue[700] : undefined};
    &:hover {
      color: ${({ status }) =>
        status === 'incoming' ? baseColors.neutral[400] : status === 'highlighted' ? baseColors.blue[700] : undefined};
    }
  `

  // Watch scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY !== 0)
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // Sync body class with mobile open/close
  useEffect(() => {
    if (isOpen) {
      document.body.setAttribute('data-mobile-menu-open', 'true')
    } else {
      document.body.removeAttribute('data-mobile-menu-open')
    }
  }, [isOpen])

  // Store isMobile and watch for changes
  useEffect(() => {
    const detectMqChange = () => {
      setIsMobile(!mqList?.matches)
    }

    detectMqChange()
    mqList?.addEventListener('change', detectMqChange)

    return () => {
      mqList?.removeEventListener('change', detectMqChange)
    }
  }, [])

  // Force close when switching from mobile to desktop
  useEffect(() => {
    const handleResize = (e: MediaQueryListEvent) => {
      if (e.matches && isOpen) setIsOpen(false)
    }

    mqList?.addEventListener('change', handleResize)

    return () => {
      mqList?.removeEventListener('change', handleResize)
    }
  }, [isOpen])

  // Force close on navigation
  useEffect(() => {
    const closeMenu = () => setIsOpen(false)

    events.on('routeChangeStart', closeMenu)

    return () => {
      events.off('routeChangeStart', closeMenu)
    }
  })
  return (
    <StickyWrapper
      css={[
        theme ? themes[theme] : null,
        {
          '--header-bg-color': theme && !isScrolled ? themes[theme]?.backgroundColor : 'white',
        },
      ]}
      data-theme={isScrolled ? undefined : theme}
    >
      <StickyHeader ref={stickyHeaderRef} data-scrolled={isScrolled}>
        <HeaderWrapper>
          <StyledLinkLogo type='navLink' href='/' aria-label={strings.ariaMenuIndexButton} ctaText='logo-tot' contextText='header'>
            <div>
              <LogoTot fill={theme === 'dark' && !isScrolled ? 'white' : lightColors.text.base} />
            </div>
          </StyledLinkLogo>
          <MobileMenuWrapper>
            <MobileMenuHeader>
              <StyledLinkLogo href='/' aria-label={strings.ariaMenuIndexButton} type='navLink' ctaText='logo-tot' contextText='header'>
                <div>
                  <LogoTot fill={lightColors.text.base} />
                </div>
              </StyledLinkLogo>
              <MenuClose aria-label={strings.ariaMenuClose} onClick={() => setIsOpen(false)} />
            </MobileMenuHeader>
            <Nav>
              {menuStructure.map((menuItem, i) =>
                !hasLeafs(menuItem) ? (
                  <MenuRoot
                    type='navLink'
                    ctaText={menuItem.label}
                    contextText='header'
                    href={menuItem.url}
                    key={menuItem.url}
                    hasLeafs={false}
                    isMobile={isMobile}
                    isScrolled={isScrolled}
                    contentfulTheme={theme}
                  >
                    {menuItem.label}
                  </MenuRoot>
                ) : (
                  <NavLinkWrapper
                    key={menuItem.label}
                    onMouseEnter={() => (isMobile ? null : setActiveLink(i))}
                    onMouseLeave={() => (isMobile ? null : setActiveLink(undefined))}
                    onClick={() => (isMobile ? setActiveLink((old) => (old === i ? undefined : i)) : null)}
                    onFocus={() => (isMobile ? null : setActiveLink(i))}
                  >
                    <MenuRoot
                      href=''
                      type='navLink'
                      ctaText={menuItem.label}
                      aria-expanded={activeLink === i}
                      hasLeafs={true}
                      aria-owns={menuItem.label}
                      isMobile={isMobile}
                      isScrolled={isScrolled}
                      contentfulTheme={theme}
                    >
                      {menuItem.label}
                      <FontAwesomeIcon
                        css={{ display: isMobile ? 'block' : 'none' }}
                        icon={activeLink === i ? faChevronUp : faChevronDown}
                      />
                    </MenuRoot>
                    <DropdownWrapper active={activeLink === i} id={menuItem.label} aria-hidden={activeLink !== i}>
                      <Dropdown>
                        {menuItem.children.map((submenuItem) => {
                          if (!hasLeafs(submenuItem)) {
                            return (
                              <StyledMenuItem
                                status={submenuItem.status}
                                type='navLink'
                                ctaText={submenuItem.label}
                                contextText='header'
                                href={submenuItem.url}
                                key={submenuItem.url}
                              >
                                {submenuItem.label}
                                {submenuItem.status === 'incoming' ? <IncomingBadge>In arrivo</IncomingBadge> : null}
                              </StyledMenuItem>
                            )
                          } else throw new Error('Menu error: only one level of nesting is supported')
                        })}
                      </Dropdown>
                    </DropdownWrapper>
                  </NavLinkWrapper>
                )
              )}
            </Nav>
            <ButtonWrapper>
              <StyledLinkTrackEvent
                type='button'
                ctaText='Accedi'
                href='https://app.tot.money/'
                ghost
                inverted={theme === 'dark' && !isScrolled}
              >
                Accedi
              </StyledLinkTrackEvent>
              <StyledLinkTrackEvent type='button' ctaText='Registrati' href='https://app.tot.money/signup'>
                Registrati
              </StyledLinkTrackEvent>
            </ButtonWrapper>
          </MobileMenuWrapper>
          <MenuOpen aria-label={strings.ariaMenuOpen} onClick={() => setIsOpen(true)} />
        </HeaderWrapper>
      </StickyHeader>
    </StickyWrapper>
  )
}

export default Header
