import styled from '@emotion/styled'
import { lightColors, baseColors } from '@totmoney/colors'

import Grid from './Grid'

import Link from 'next/link'
import Image from 'next/image'
import { Label } from './Typography'

const BreadcrumbsInner = styled.div({
  gridColumn: '1/-1',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  paddingBlock: '0.75rem',
})

const Logo = styled.div({
  display: 'flex',
  alignItems: 'center',

  ['a']: {
    lineHeight: '0',
  },
})

const BradcrumbsList = styled.ul({
  display: 'flex',
  gap: '.5rem',
  padding: '0',
  margin: '0',
  color: lightColors.neutral.lowest,

  ['li']: {
    paddingInlineStart: '1rem',
    fontWeight: '400',
    listStyle: 'none',
    position: 'relative',
    background: 'url(/illustrations/chevron.svg) no-repeat',
    backgroundPosition: '0% 50%',

    ['&::marker']: {
      margin: '3rem',
    },

    ['a']: {
      color: 'inherit',
    },
  },
})

export type TCrumb = {
  url?: string
  label: string
}

export type TBreadcrumbs = {
  crumbs?: TCrumb[]
}

const Breadcrumbs = ({ crumbs }: TBreadcrumbs) => {
  return (
    <Grid>
      <BreadcrumbsInner>
        <Logo>
          <Link href='/'>
            <Image src='/images/logo-white.svg' alt='tot' width='38' height='16' />
          </Link>
        </Logo>

        {crumbs && (
          <BradcrumbsList>
            {crumbs.map((crumb) => (
              <li key={crumb.label}>
                {crumb.url && (
                  <Link passHref href={crumb.url}>
                    <Label size='s'>{crumb.label}</Label>
                  </Link>
                )}
                {!crumb.url && <span>{crumb.label}</span>}
              </li>
            ))}
          </BradcrumbsList>
        )}
      </BreadcrumbsInner>
    </Grid>
  )
}

export default Breadcrumbs
